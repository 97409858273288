import * as React from "react";
import Alert from "@mui/material/Alert";

export default function ClassAdjustAlert(props) {
	return (
		<Alert
			variant={props.variant}
			severity={props.severity}
		>
			{props.message}
		</Alert>
	);
}
