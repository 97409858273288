import React, { useContext, useEffect, useState, useMemo, useRef } from "react";
import { Typography, Grid, Button } from "@mui/material";
import BlueDataErrorLog from "./components/BlueDataErrorLog";

export default function SendMail() {
	const [formState, setFormState] = useState({
		subject: "",
		body: "",
		fromEmail: "",
	});
	return (
		<div className="w-full flex flex-col items-center justify-center">
			<div>Send Email Through SMTP</div>
		</div>
	);
}
