import * as React from "react";
import { GridFooterContainer, GridFooter } from "@mui/x-data-grid";
import { useState, useEffect, useCallback, useRef, useReducer } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import isAuthenticated from "../../../../components/Utils/isAuthenticated";
import Snackbar from "@mui/material/Snackbar";
import { roles } from "../../../../components/Utils/Descriptions";
import { useMutation, useQueryClient } from "react-query";
import ClassAdjustCombineDialog from "./ClassAdjustCombineDialog";
import { deleteAdjustedClassId } from "../../../../components/Api/Deletes";
import UndoCombineDialog from "./undoCombineDialog";
import ConfirmEvaluationDialog from "./ConfirmEvaluationDialog";
import { postAllClassesClassAdjust } from "../../../../components/Api/Post";
import SubmitAllSuitableCoursesDialog from "./SubmitAllSuitableCoursesDialog";
import BatchUpdateDialog from "./BatchUpdateDialog";
import ConfirmCancelDialog from "./ConfirmCancelDialog";
import HeaderSection from "./HeaderSection";
import InstructorCell from "./InstructorCell";
import DataGridTable from "./DataGridTable";
import ClassPanel from "./ClassPanel";
import InstanceClassAdjustModal from "./InstancedClassAdjustModal";

export default function ClassAdjustTable(props) {
	const auth = isAuthenticated();
	const token = auth.token;
	const {
		selectedClass,
		setSelectedClass,
		batchUpdated,
		setBatchUpdated,
		customCheckboxSelection,
		setCustomCheckboxSelection,
	} = props;

	const queryClient = useQueryClient();
	const previousRowsRef = useRef(props.rows);
	const [dateFormat, setDateFormat] = useState("DD/MM/YY");

	const initialState = {
		checkboxSelectionState: [],
		snackbarOpen: false,
		snackbarMessage: "",
		searchValue: "",
		addInstructor: false,
		openClassAdjustCombineDialog: false,
		undoDialogOpen: false,
		classesForCombining: [],
		teamTeachingAgree: false,
		unwantedCombination: false,
		evaluationDialogState: "",
		openEvaluationDialog: false,
		classForEvaluatedDialog: null,
		clickedAddNotAllowed: false,
		listOfUnsuitableCourses: [],
		listOfSuitableCourses: [],
		openSubmitAllSuitableCoursesDialog: false,
		submitAllLoading: false,
		filterKey: "",
		reviewingClasses: false,
		prevRowsState: [],
		isDragging: false,
		startRowIndex: null,
		openBatchUpdateDialog: false,
		openConfirmCancelDialog: false,
		confirmSubmitAllSelected: false,
		previousRowsCombine: [],
		openAddInstancedClass: false,
	};

	function reducer(state, action) {
		switch (action.type) {
			case "SET_CHECKBOX_SELECTION_STATE":
				return { ...state, checkboxSelectionState: action.payload };
			case "SET_CLASSES_FOR_COMBINING":
				return { ...state, classesForCombining: action.payload };
			case "SET_TEAM_TEACHING_AGREE":
				return { ...state, teamTeachingAgree: action.payload };
			case "SET_UNDO_DIALOG_OPEN":
				return { ...state, undoDialogOpen: action.payload };
			case "SET_EVALUATION_DIALOG_STATE":
				return { ...state, evaluationDialogState: action.payload };
			case "SET_OPEN_EVALUATION_DIALOG":
				return { ...state, openEvaluationDialog: action.payload };
			case "SET_CLASS_FOR_EVALUATED_DIALOG":
				return { ...state, classForEvaluatedDialog: action.payload };
			case "SET_CLICKED_ADD_NOT_ALLOWED":
				return { ...state, clickedAddNotAllowed: action.payload };
			case "SET_LIST_OF_UNSUITABLE_COURSES":
				return { ...state, listOfUnsuitableCourses: action.payload };
			case "SET_LIST_OF_SUITABLE_COURSES":
				return { ...state, listOfSuitableCourses: action.payload };
			case "SET_OPEN_SUBMIT_ALL_SUITABLE_COURSES_DIALOG":
				return { ...state, openSubmitAllSuitableCoursesDialog: action.payload };
			case "SET_SUBMIT_ALL_LOADING":
				return { ...state, submitAllLoading: action.payload };
			case "SET_FILTER_KEY":
				return { ...state, filterKey: action.payload };
			case "SET_REVIEWING_CLASSES":
				return { ...state, reviewingClasses: action.payload };
			case "SET_PREV_ROWS_STATE":
				return { ...state, prevRowsState: action.payload };
			case "SET_OPEN_BATCH_UPDATE_DIALOG":
				return { ...state, openBatchUpdateDialog: action.payload };
			case "SET_OPEN_CONFIRM_CANCEL_DIALOG":
				return { ...state, openConfirmCancelDialog: action.payload };
			case "SET_CONFIRM_SUBMIT_ALL_SELECTED":
				return { ...state, confirmSubmitAllSelected: action.payload };
			case "SET_OPEN_CLASS_ADJUST_COMBINE_DIALOG":
				return { ...state, openClassAdjustCombineDialog: action.payload };
			case "SET_UNWANTED_COMBINATION":
				return { ...state, unwantedCombination: action.payload };

			case "SET_SEARCH_VALUE":
				return { ...state, searchValue: action.payload };
			case "SET_SNACKBAR_OPEN":
				return { ...state, snackbarOpen: action.payload };
			case "SET_SNACKBAR_MESSAGE":
				return { ...state, snackbarMessage: action.payload };
			case "SET_IS_DRAGGING":
				return { ...state, isDragging: action.payload };
			case "SET_START_ROW_INDEX":
				return { ...state, startRowIndex: action.payload };
			case "SET_STATE":
				return { ...state, ...action.payload };
			case "SET_ADD_INSTRUCTOR":
				return { ...state, addInstructor: action.payload };
			case "SET_OPEN_ADD_INSTANCED_CLASS":
				return { ...state, openAddInstancedClass: action.payload };
			default:
				return state;
		}
	}

	const [state, dispatch] = useReducer(reducer, initialState);

	const {
		checkboxSelectionState,
		snackbarOpen,
		snackbarMessage,
		searchValue,
		addInstructor,
		openClassAdjustCombineDialog,
		undoDialogOpen,
		classesForCombining,
		teamTeachingAgree,
		unwantedCombination,
		evaluationDialogState,
		openEvaluationDialog,
		classForEvaluatedDialog,
		clickedAddNotAllowed,
		listOfUnsuitableCourses,
		listOfSuitableCourses,
		openSubmitAllSuitableCoursesDialog,
		submitAllLoading,
		filterKey,
		reviewingClasses,
		prevRowsState,
		isDragging,
		openBatchUpdateDialog,
		openConfirmCancelDialog,
		confirmSubmitAllSelected,
		previousRowsCombine,
		openAddInstancedClass,
	} = state;

	const setOpenConfirmCancelDialog = useCallback((value) => {
		dispatch({ type: "SET_OPEN_CONFIRM_CANCEL_DIALOG", payload: value });
	}, []);
	const setConfirmSubmitAllSelected = useCallback((value) => {
		dispatch({ type: "SET_CONFIRM_SUBMIT_ALL_SELECTED", payload: value });
	}, []);
	const setOpenBatchUpdateDialog = useCallback((value) => {
		dispatch({ type: "SET_OPEN_BATCH_UPDATE_DIALOG", payload: value });
	}, []);
	const setOpenSubmitAllSuitableCoursesDialog = useCallback((value) => {
		dispatch({
			type: "SET_OPEN_SUBMIT_ALL_SUITABLE_COURSES_DIALOG",
			payload: value,
		});
	}, []);
	const setSubmitAllLoading = useCallback((value) => {
		dispatch({ type: "SET_SUBMIT_ALL_LOADING", payload: value });
	}, []);
	const setOpenEvaluationDialog = useCallback((value) => {
		dispatch({ type: "SET_OPEN_EVALUATION_DIALOG", payload: value });
	}, []);
	const setOpenClassAdjustCombineDialog = useCallback((value) => {
		dispatch({ type: "SET_OPEN_CLASS_ADJUST_COMBINE_DIALOG", payload: value });
	}, []);

	const setUnwantedCombination = useCallback((value) => {
		dispatch({ type: "SET_UNWANTED_COMBINATION", payload: value });
	}, []);
	const setOpenUndoDialog = useCallback((value) => {
		dispatch({ type: "SET_UNDO_DIALOG_OPEN", payload: value });
	}, []);
	const setTeamTeachingAgree = useCallback((value) => {
		dispatch({ type: "SET_TEAM_TEACHING_AGREE", payload: value });
	}, []);
	const setAddInstructor = useCallback((value) => {
		dispatch({ type: "SET_ADD_INSTRUCTOR", payload: value });
	}, []);

	const setClassesForCombining = useCallback((list) => {
		dispatch({ type: "SET_CLASSES_FOR_COMBINING", payload: list });
	}, []);

	const setSearchValue = useCallback((value) => {
		dispatch({ type: "SET_SEARCH_VALUE", payload: value });
	}, []);

	const setFilterKey = useCallback((value) => {
		dispatch({ type: "SET_FILTER_KEY", payload: value });
	}, []);

	const handleOpenAddInstancedClass = useCallback((value) => {
		dispatch({ type: "SET_OPEN_ADD_INSTANCED_CLASS", payload: value });
	}, []);

	const setCheckboxSelectionState = useCallback((value) => {
		dispatch({ type: "SET_CHECKBOX_SELECTION_STATE", payload: value });
	}, []);

	useEffect(() => {
		const handleMouseUp = () => {
			dispatch({
				type: "SET_IS_DRAGGING",
				payload: false,
			});
			dispatch({
				type: "SET_START_ROW_INDEX",
				payload: null,
			});
		};

		window.addEventListener("mouseup", handleMouseUp);
		return () => {
			window.removeEventListener("mouseup", handleMouseUp);
		};
	}, []);

	// const handleMouseDown = (event, rowIndex) => {
	// 	dispatch({
	// 		type: "SET_IS_DRAGGING",
	// 		payload: true,
	// 	});
	// 	dispatch({
	// 		type: "SET_START_ROW_INDEX",
	// 		payload: rowIndex,
	// 	});

	// 	// Initially select the row where mouse is pressed down
	// 	handleSelectionChange(rowIndex);
	// };

	// const handleMouseEnter = (event, rowIndex) => {
	// 	if (isDragging && startRowIndex !== null) {
	// 		handleSelectionChange(rowIndex);
	// 	}
	// };

	useEffect(() => {
		//if a row has pending but is not in customCheckboxSelection, remove pending
		const updatedRows = props.rows.map((r) => {
			if (
				r.evaluate === "Pending" &&
				!customCheckboxSelection.includes(r.classid)
			) {
				return { ...r, evaluate: "" };
			}
			return r;
		});
		props.setRows(updatedRows);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customCheckboxSelection]);

	const handleSelectionChange = useCallback(
		(rowIndex) => {
			setCustomCheckboxSelection((prevSelection) => {
				const newSelection = [...prevSelection];
				if (newSelection.includes(rowIndex)) {
					newSelection.splice(newSelection.indexOf(rowIndex), 1);
				} else {
					// Update row with evaluate: "Pending"
					const updatedRows = props.rows.map((r) => {
						if (r.classid === rowIndex) {
							return { ...r, evaluate: "Pending" };
						}
						return r;
					});
					props.setRows(updatedRows);
					newSelection.push(rowIndex);
				}
				return newSelection;
			});
		},
		[props.rows, setCustomCheckboxSelection]
	);

	useEffect(() => {
		if (
			checkboxSelectionState.length === 1 &&
			customCheckboxSelection.length === 0
		) {
			const selectedClass = props.rows.find(
				(row) => row.classid === checkboxSelectionState[0]
			);
			setSelectedClass(selectedClass);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checkboxSelectionState]);

	useEffect(() => {
		if (customCheckboxSelection.length > 0) {
			setSelectedClass(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customCheckboxSelection]);

	useEffect(() => {
		if (customCheckboxSelection.length === 0) {
			setSelectedClass(
				props.rows.find((row) => row.classid === checkboxSelectionState[0])
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.rows]);

	useEffect(() => {
		if (selectedClass) {
			const combinedWith = selectedClass.combine;
			let combinedWithArray = [];

			if (combinedWith.length > 5) {
				combinedWithArray = combinedWith.split(",");
			} else {
				combinedWithArray = [combinedWith];
			}
			const allClassesExist = combinedWithArray.every((classid) =>
				props.rowsFromClassIds.some((row) => row.classid === Number(classid))
			);
			let combinedRowList = [];
			let notCombinedRowList = [];
			let combiningDict = {};
			props.rows.forEach((row) => {
				if (row.combine.length > 0) {
					const combinedList = row.combine
						.split(",")
						.map((classid) => classid.trim());
					combiningDict[row.classid] = {
						listOfCombined: combinedList,
						row: row,
					};
					combinedRowList.push(row);
				} else {
					notCombinedRowList.push(row);
				}
			});
			let updatedRows = props.rows.map((row) => {
				const selectedClassId = String(row.classid);
				let combinedWithValue = row.classid;
				Object.keys(combiningDict).forEach((key) => {
					const combinedList = combiningDict[key].listOfCombined;
					const parentClassId = key;

					if (combinedList.includes(selectedClassId)) {
						combinedWithValue = parentClassId;
						row = {
							...row,
							isCombined: true,
							combinedWith: combinedWithValue,
						};
					}
				});
				if (allClassesExist && row.classid === selectedClass.classid) {
					row = { ...row, allowUndo: true };
				}
				return row;
			});
			if (
				JSON.stringify(updatedRows) !== JSON.stringify(previousRowsRef.current)
			) {
				props.setRows(updatedRows);
				previousRowsRef.current = updatedRows;
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedClass, props.rowsFromClassIds, props.rows]);

	useEffect(() => {
		if (
			checkboxSelectionState.length > 0 &&
			props.rows.length > 0 &&
			props.combiningMode
		) {
			let classes = props.rows.filter((row) =>
				checkboxSelectionState.includes(row.classid)
			);
			let commonInstructors = classes[0].roles;
			classes.forEach((classObj) => {
				commonInstructors = commonInstructors.filter((role) =>
					classObj.roles.some((r) => Number(r.empid) === Number(role.empid))
				);
			});
			if (commonInstructors.length < 1) {
				dispatch({ type: "SET_SNACKBAR_OPEN", payload: true });
				dispatch({
					type: "SET_SNACKBAR_MESSAGE",
					payload:
						"Classes must have at least one instructor in common to combine.",
				});
				dispatch({
					type: "SET_CHECKBOX_SELECTION_STATE",
					payload: [checkboxSelectionState[0]],
				});
				dispatch({ type: "SET_CLASSES_FOR_COMBINING", payload: [] });
			} else {
				let teamTeaching = false;
				classes.forEach((classObj) => {
					if (classObj.roles.length > 1) {
						teamTeaching = true;
					}
				});
				dispatch({ type: "SET_TEAM_TEACHING_AGREE", payload: teamTeaching });
				dispatch({ type: "SET_CLASSES_FOR_COMBINING", payload: classes });
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checkboxSelectionState, props.rows]);

	useEffect(() => {
		if (props.unsavedChanges && clickedAddNotAllowed) {
			props.setWarnUnsavedChanges(true);
		} else {
			props.setWarnUnsavedChanges(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.unsavedChanges, clickedAddNotAllowed]);

	const handleSelectionModelChange = (newSelection) => {
		//limit of one selection
		if (props.combiningMode) {
			dispatch({ type: "SET_CHECKBOX_SELECTION_STATE", payload: newSelection });
		} else {
			if (newSelection.length > 1) {
				newSelection.shift();
			}
		}
		dispatch({ type: "SET_CHECKBOX_SELECTION_STATE", payload: newSelection });
	};

	const handleSearch = useCallback(
		(e) => {
			const value = e.target.value;
			setSearchValue(value);

			if (value === "") {
				props.setRows(props.rowsForTableOriginal);
			} else {
				const filteredRows = props.rowsForTableOriginal.filter((row) => {
					return (
						row.classid.toString().includes(value) ||
						row.ctitle.toLowerCase().includes(value.toLowerCase()) ||
						row.roles.some((role) =>
							role.name
								? role.name.toLowerCase().includes(value.toLowerCase())
								: `${role.user.last_name}, ${role.user.first_name}`
										.toLowerCase()
										.includes(value.toLowerCase())
						)
					);
				});
				props.setRows(filteredRows);
			}
		},
		[dispatch, props.rowsForTableOriginal, props.setRows]
	);

	const deleteClassMutation = useMutation(
		async ({ token, classid }) => {
			await deleteAdjustedClassId({ token, classid });
		},
		{
			onSuccess: () => {
				console.log("Successfully deleted class");
				// Invalidate and refetch
				queryClient.invalidateQueries([
					"adjustedClassIdObjectsByDeptAndTerm",
					props.deptId,
					props.termNumber,
				]);
				queryClient.invalidateQueries([
					"classIdObjectsByDeptAndTerm",
					props.deptId,
					props.termNumber,
				]);
			},
			onError: (error) => {
				console.error("Failed to update class:", error);
			},
		}
	);

	const handleTeamVisibility = (row) => {
		const teamNames = row.roles.map((role) => {
			// Corrected logic to ensure proper ternary operation
			return role.name
				? `(${role.name})`
				: `(${role.user.last_name}, ${role.user.first_name})`;
		});
		const teamName = teamNames.join(", ");

		const updatedRows = [...props.rows];
		const index = updatedRows.findIndex((r) => r.classid === row.classid);
		updatedRows[index].teamName = teamName;
		updatedRows[index].nameExpanded = !updatedRows[index].nameExpanded;

		props.setRows(updatedRows);
	};

	const handleChangeRole = (e, instructor, row) => {
		const role = e.target.value;

		const updatedRows = [...props.rows];

		const index = updatedRows.findIndex((r) => r.classid === row.classid);

		const roleIndex = updatedRows[index].roles.findIndex(
			(r) => r.name === instructor.name
		);
		updatedRows[index].roles[roleIndex].class_role = role;

		props.setUnsavedChanges(true);

		props.setRows(updatedRows);
	};

	const handleCloseSnackbar = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		dispatch({ type: "SET_SNACKBAR_OPEN", payload: false });
	};

	const handleDateChangeEndDate = (date) => {
		setSelectedClass((prev) => ({
			...prev,
			c_edate: date.format("YYYY-MM-DD"),
		}));

		const updatedRows = props.rows.map((row) => {
			if (row.classid === selectedClass.classid) {
				return { ...row, c_edate: date.format("YYYY-MM-DD") };
			}
			return row;
		});

		props.setRows(updatedRows);

		const isChanged =
			JSON.stringify(props.originalRows) !== JSON.stringify(updatedRows);
		props.setUnsavedChanges(isChanged);
	};

	const handleDateChangeSurveyStart = (date) => {
		setSelectedClass((prev) => ({
			...prev,
			survey_start: date.format("YYYY-MM-DD"),
		}));

		const updatedRows = props.rows.map((row) => {
			if (row.classid === selectedClass.classid) {
				return { ...row, survey_start: date.format("YYYY-MM-DD") };
			}
			return row;
		});

		props.setRows(updatedRows);

		const isChanged =
			JSON.stringify(props.originalRows) !== JSON.stringify(updatedRows);
		props.setUnsavedChanges(isChanged);
	};

	const handleDateChangeSurveyEnd = (date) => {
		setSelectedClass((prev) => ({
			...prev,
			survey_end: date.format("YYYY-MM-DD"),
		}));

		const updatedRows = props.rows.map((row) => {
			if (row.classid === selectedClass.classid) {
				return { ...row, survey_end: date.format("YYYY-MM-DD") };
			}
			return row;
		});

		props.setRows(updatedRows);

		const isChanged =
			JSON.stringify(props.originalRows) !== JSON.stringify(updatedRows);
		props.setUnsavedChanges(isChanged);
	};

	const handleDateChangeExamDate = (date) => {
		setSelectedClass((prev) => ({
			...prev,
			examdate: date.format("YYYY-MM-DD"),
		}));

		const updatedRows = props.rows.map((row) => {
			if (row.classid === selectedClass.classid) {
				return { ...row, examdate: date.format("YYYY-MM-DD") };
			}
			return row;
		});
		//get the index of the selected class
		props.setRows(updatedRows);
		const isChanged =
			JSON.stringify(props.originalRows) !== JSON.stringify(updatedRows);
		props.setUnsavedChanges(isChanged);
	};

	const handleDiscardChanges = () => {
		props.setRows(props.originalRows);
		queryClient.invalidateQueries([
			"adjustedClassIdObjectsByDeptAndTerm",
			props.deptId,
			props.termNumber,
		]);
		queryClient.invalidateQueries([
			"classIdObjectsByDeptAndTerm",
			props.deptId,
			props.termNumber,
		]);

		props.setUnsavedChanges(false);
	};

	const handleSaveChanges = async () => {
		const updatedSelectedClass = selectedClass;
		if (!selectedClass.evaluate) {
			updatedSelectedClass.evaluate = "Yes";
			await props.updateClassMutation.mutateAsync({
				token,
				selectedClass: updatedSelectedClass,
			});
		} else {
			await props.updateClassMutation.mutateAsync({ token, selectedClass });
		}

		props.setUnsavedChanges(false);
	};

	const handleUndo = async () => {
		const classid = selectedClass.classid;
		await deleteClassMutation.mutateAsync({ token, classid });
		dispatch({ type: "SET_UNDO_DIALOG_OPEN", payload: false });
	};

	const handleToggleExclude = (value) => {
		const updatedRows = props.rows.map((row) => {
			if (row.classid === selectedClass.classid) {
				return { ...row, evaluate: value ? "No" : "Yes" };
			}
			return row;
		});
		props.setRows(updatedRows);
		const isChanged =
			JSON.stringify(props.originalRows) !== JSON.stringify(updatedRows);
		props.setUnsavedChanges(isChanged);
	};

	const deleteInstructor = (event, instructor) => {
		const updatedRows = props.rows.map((row) => {
			if (row.classid === selectedClass.classid) {
				const updatedRoles = row.roles.filter(
					(role) => role.empid !== instructor.empid
				);
				return { ...row, roles: updatedRoles, teamsize: updatedRoles.length };
			}
			return row;
		});

		props.setRows(updatedRows);
		const isChanged =
			JSON.stringify(props.originalRows) !== JSON.stringify(updatedRows);
		props.setUnsavedChanges(isChanged);
		//add api call to delete from roles table
	};

	const undoCombine = async () => {
		const combinedWith = selectedClass.combine;
		let combinedWithArray = [];
		if (combinedWith.length > 5) {
			combinedWithArray = combinedWith.split(",");
		} else {
			combinedWithArray = [combinedWith];
		}
		const allClassesExist = combinedWithArray.every((classid) =>
			props.rowsFromClassIds.some((row) => row.classid === Number(classid))
		);
		if (selectedClass.allowUndo && allClassesExist) {
			dispatch({ type: "SET_UNDO_DIALOG_OPEN", payload: true });
		}
	};

	const renderInstructorNameTable = (params) => {
		return (
			<InstructorCell
				row={params.row}
				handleTeamVisibility={handleTeamVisibility}
			/>
		);
	};

	const handleClick = async (event, row, state) => {
		event.stopPropagation();
		if (state === "evaluated") {
			dispatch({ type: "SET_EVALUATION_DIALOG_STATE", payload: "evaluated" });
			dispatch({ type: "SET_CLASS_FOR_EVALUATED_DIALOG", payload: row });
			dispatch({ type: "SET_OPEN_EVALUATION_DIALOG", payload: true });
		} else if (state === "excluded") {
			dispatch({ type: "SET_EVALUATION_DIALOG_STATE", payload: "excluded" });
			dispatch({ type: "SET_CLASS_FOR_EVALUATED_DIALOG", payload: row });
			dispatch({ type: "SET_OPEN_EVALUATION_DIALOG", payload: true });
		} else if (state === "pending") {
			//remove from customCheckboxSelection
			setCustomCheckboxSelection(
				customCheckboxSelection.filter((id) => id !== row.classid)
			);
			const updatedRows = await Promise.all(
				props.rows.map(async (r) => {
					if (r.classid === row.classid) {
						return { ...r, evaluate: "" };
					}
					return r;
				})
			);
			props.setRows(updatedRows);
		} else if (state === "neither") {
			//add class to customCheckboxSelection
			setCustomCheckboxSelection([...customCheckboxSelection, row.classid]);
			const updatedRows = await Promise.all(
				props.rows.map(async (r) => {
					if (r.classid === row.classid) {
						return { ...r, evaluate: "Pending" };
					}
					return r;
				})
			);

			props.setRows(updatedRows);
		}
	};

	const handleEvaluatedDialogConfirmation = async (classForConfirm, state) => {
		const classid = classForConfirm.classid;
		const updatedRows = await Promise.all(
			props.rows.map(async (r) => {
				if (r.classid === classid) {
					if (state === "evaluated") {
						await deleteClassMutation.mutateAsync({
							token,
							classid: r.classid,
						});
						return { ...r, evaluate: "" };
					} else if (state === "excluded") {
						//change evaluate on classForConfirm to yes before api call
						await props.updateClassMutation.mutateAsync({
							token,
							selectedClass: { ...r, evaluate: "Yes" },
						});
						return { ...r, evaluate: "Yes" };
					}
				}

				return r;
			})
		);
		props.setRows(updatedRows);
		dispatch({ type: "SET_OPEN_EVALUATION_DIALOG", payload: false });
	};

	const handleClickAddClassCheckbox = (event) => {
		if (props.unsavedChanges) {
			dispatch({ type: "SET_CLICKED_ADD_NOT_ALLOWED", payload: true });
			setTimeout(() => {
				dispatch({ type: "SET_CLICKED_ADD_NOT_ALLOWED", payload: false });
			}, 4000);
		}
	};

	const getRowClassName = useCallback((params) => {
		return `super-app-theme--${
			params.row.evaluate === "Yes"
				? "evaluation"
				: params.row.evaluate === "No"
				? "noEvaluation"
				: ""
		}`;
	}, []);

	const postAllSuitableClassesMutation = useMutation(
		async ({ token, classesToSubmit }) => {
			await postAllClassesClassAdjust({ token, classesToSubmit });
		},
		{
			onSuccess: () => {
				dispatch({ type: "SET_STATE", payload: initialState });
				// dispatch({ type: "SET_SUBMIT_ALL_LOADING", payload: false });
				// dispatch({
				// 	type: "SET_OPEN_SUBMIT_ALL_SUITABLE_COURSES_DIALOG",
				// 	payload: false,
				// });
				// dispatch({ type: "SET_REVIEWING_CLASSES", payload: false });
				// dispatch({ type: "SET_LIST_OF_SUITABLE_COURSES", payload: [] });
				// dispatch({ type: "SET_LIST_OF_UNSUITABLE_COURSES", payload: [] });
				setFilterKey(filterKey + 1);
				// dispatch({ type: "SET_CONFIRM_SUBMIT_ALL_SELECTED", payload: false });
				setCustomCheckboxSelection([]);

				queryClient.invalidateQueries([
					"adjustedClassIdObjectsByDeptAndTerm",
					props.deptId,
					props.termNumber,
				]);
				queryClient.invalidateQueries([
					"classIdObjectsByDeptAndTerm",
					props.deptId,
					props.termNumber,
				]);
				props.setUnsavedChanges(false);
			},
			onError: (error) => {
				dispatch({ type: "SET_SUBMIT_ALL_LOADING", payload: false });
				dispatch({ type: "SET_REVIEWING_CLASSES", payload: false });
				dispatch({ type: "SET_LIST_OF_SUITABLE_COURSES", payload: [] });
				dispatch({ type: "SET_LIST_OF_UNSUITABLE_COURSES", payload: [] });

				alert(
					"Error submitting classes. Please contact TSS by phone or email."
				);
				console.error("Failed to update class:", error);
			},
		}
	);

	const handleReviewAllSelected = async () => {
		dispatch({ type: "SET_PREV_ROWS_STATE", payload: props.rows });
		//remove all classes that are not in customCheckboxSelection
		const updatedFilteredRows = props.rows.filter((row) =>
			customCheckboxSelection.includes(row.classid)
		);
		props.setRows(updatedFilteredRows);

		dispatch({ type: "SET_CONFIRM_SUBMIT_ALL_SELECTED", payload: true });
		setFilterKey(filterKey + 1);
	};

	const handleSubmitAllSelected = async () => {
		//get rows from customCheckboxSelection and submit
		let selectedClassesList = [];
		dispatch({ type: "SET_SUBMIT_ALL_LOADING", payload: true });
		customCheckboxSelection.forEach((classid) => {
			const selectedClass = props.rows.find((row) => row.classid === classid);
			selectedClassesList.push(selectedClass);
		});
		await postAllSuitableClassesMutation.mutateAsync({
			token,
			classesToSubmit: selectedClassesList,
		});
	};

	const handleSubmitAllSuitable = async () => {
		let suitableCoursesList = [];
		let existingCoursesList = [];
		let excludedCoursesList = [];
		dispatch({ type: "SET_PREV_ROWS_STATE", payload: props.rows });
		const updatedRows = props.rows.map((row) => {
			if (row.evaluate === "" || !row.evaluate) {
				if (
					row.roles.length > 0 &&
					row.csize >= 4 &&
					row.blkid &&
					row.blkid.length > 0
				) {
					suitableCoursesList.push(row);
					return { ...row, evaluate: "Yes" };
				} else {
					excludedCoursesList.push(row);
					return { ...row, evaluate: "Caution" };
				}
			} else if (row.evaluate === "Yes" || row.evaluate === "No") {
				existingCoursesList.push(row);
				return row;
			}
			return row;
		});
		if (suitableCoursesList.length < 1) {
			alert(
				"There are no suitable classes to submit that have not already been submitted."
			);
			return;
		}
		dispatch({
			type: "SET_LIST_OF_SUITABLE_COURSES",
			payload: suitableCoursesList,
		});
		dispatch({ type: "SET_REVIEWING_CLASSES", payload: true });
		dispatch({ type: "SET_CHECKBOX_SELECTION_STATE", payload: [] });

		props.setSelectedClass(null);
		//sort updated rows based on evaluate no = first, excluded true = second, evaluate yes = third
		updatedRows.sort((a, b) => {
			const order = ["Yes", "Pending", "No", "Caution", null, ""];

			const index1 = order.indexOf(a.evaluate);
			const index2 = order.indexOf(b.evaluate);

			if (index1 < index2) {
				return -1;
			}
			if (index1 > index2) {
				return 1;
			}
			return 0;
		});
		setFilterKey(filterKey + 1);
		props.setRows(updatedRows);
	};

	const handleCancelSubmitAllSuitable = () => {
		dispatch({ type: "SET_REVIEWING_CLASSES", payload: false });
		props.setRows(prevRowsState);
	};

	const handleCancelSubmitAllSelected = () => {
		if (batchUpdated) {
			dispatch({ type: "SET_OPEN_CONFIRM_CANCEL_DIALOG", payload: true });
		} else {
			dispatch({ type: "SET_CONFIRM_SUBMIT_ALL_SELECTED", payload: false });

			//set pending to false for all classes
			queryClient.invalidateQueries([
				"adjustedClassIdObjectsByDeptAndTerm",
				props.deptId,
				props.termNumber,
			]);
			queryClient.invalidateQueries([
				"classIdObjectsByDeptAndTerm",
				props.deptId,
				props.termNumber,
			]);

			setCustomCheckboxSelection([]);
		}
	};

	const handleConfirmSubmitAllSuitable = async () => {
		dispatch({ type: "SET_SUBMIT_ALL_LOADING", payload: true });

		await postAllSuitableClassesMutation.mutateAsync({
			token,
			classesToSubmit: listOfSuitableCourses,
		});
	};

	const handleSaveAttempt = (event) => {
		const { message, shouldPrevent } = validateClass(props.selectedClass);

		if (shouldPrevent) {
			event.preventDefault();
			dispatch({ type: "SET_SNACKBAR_MESSAGE", payload: message });
			dispatch({ type: "SET_SNACKBAR_OPEN", payload: true });
		}
	};

	const hasNoRolesCheck = (course) => {
		return !course.roles || course.roles.length < 1;
	};

	const hasNoBlockIdCheck = (course) => {
		return !course.blkid || course.blkid.length === 0;
	};

	const isClassSizeTooSmallCheck = (course) => {
		return course.csize < 4;
	};

	const isClassSizeZeroCheck = (course) => {
		return course.csize === 0;
	};

	const hasNoRoles =
		!props.selectedClass?.roles || props.selectedClass.roles?.length < 1;
	const hasNoBlockId =
		!props.selectedClass?.blkid || props.selectedClass.blkid?.length === 0;
	const isClassSizeTooSmall = props.selectedClass?.csize < 4;
	const isClassSizeZero = props.selectedClass?.csize === 0;

	const validateClass = (selectedClass) => {
		if (!selectedClass?.roles || selectedClass.roles.length < 1) {
			return {
				message: "An assigned instructor is necessary to save a course.",
				shouldPrevent: true,
			};
		}
		if (selectedClass?.csize < 4) {
			return {
				message: "Classes with less than 4 students must be combined.",
				shouldPrevent: true,
			};
		}
		if (!selectedClass?.blkid || selectedClass.blkid.length === 0) {
			return {
				message: "A question block is necessary to save a course.",
				shouldPrevent: true,
			};
		}
		if (selectedClass?.csize === 0) {
			return {
				message: "A class size of 4 or more is necessary to save a course.",
				shouldPrevent: true,
			};
		}

		// If no validation errors
		return { message: "", shouldPrevent: false };
	};

	const handleCombineClass = async (row) => {
		dispatch({ type: "SET_PREVIOUS_ROWS_COMBINE", payload: props.rows });

		//filter out classes that do not have a common instructor
		const classesForCombining = props.rows.filter((r) =>
			r.roles.some((role) =>
				row.roles.some((r) => Number(r.empid) === Number(role.empid))
			)
		);
		props.setRows(classesForCombining);
		props.setCombiningMode(true);
	};

	const handleCancelCombine = () => {
		props.setRows(previousRowsCombine);
		queryClient.invalidateQueries([
			"adjustedClassIdObjectsByDeptAndTerm",
			props.deptId,
			props.termNumber,
		]);
		queryClient.invalidateQueries([
			"classIdObjectsByDeptAndTerm",
			props.deptId,
			props.termNumber,
		]);

		props.setCombiningMode(false);
	};

	return (
		<div className="flex h-full w-full  min-h-[90dvh] max-h-screen text-zinc-900 ">
			<InstanceClassAdjustModal
				open={openAddInstancedClass}
				setOpen={handleOpenAddInstancedClass}
				selectedClass={selectedClass}
				rows={props.rows}
				setRows={props.setRows}
				unsavedChanges={props.unsavedChanges}
				setUnsavedChanges={props.setUnsavedChanges}
				handleTeamVisibility={handleTeamVisibility}
				deptId={props.deptId}
				termNumber={props.termNumber}
			/>
			<ConfirmCancelDialog
				rows={props.rows}
				setRows={props.setRows}
				setCustomCheckboxSelection={props.setCustomCheckboxSelection}
				open={openConfirmCancelDialog}
				setOpen={setOpenConfirmCancelDialog}
				setUnsavedChanges={props.setUnsavedChanges}
				setBatchUpdated={setBatchUpdated}
				setConfirmSubmitAllSelected={setConfirmSubmitAllSelected}
			/>
			<BatchUpdateDialog
				open={openBatchUpdateDialog}
				setOpen={setOpenBatchUpdateDialog}
				rows={props.rows}
				setRows={props.setRows}
				setUnsavedChanges={props.setUnsavedChanges}
				customCheckboxSelection={customCheckboxSelection}
				setBatchUpdated={setBatchUpdated}
				allBlockItems={props.allBlockItems}
				handleChangeBlkId={props.handleChangeBlkId}
				selectedClass={selectedClass}
				combiningMode={props.combiningMode}
			/>

			<SubmitAllSuitableCoursesDialog
				open={openSubmitAllSuitableCoursesDialog}
				setOpen={setOpenSubmitAllSuitableCoursesDialog}
				listOfSuitableCourses={listOfSuitableCourses}
				listOfUnsuitableCourses={listOfUnsuitableCourses}
				postAllSuitableClassesMutation={postAllSuitableClassesMutation}
				renderInstructorNameTable={renderInstructorNameTable}
				submitAllLoading={submitAllLoading}
				setSubmitAllLoading={setSubmitAllLoading}
				token={token}
			/>

			<ConfirmEvaluationDialog
				open={openEvaluationDialog}
				setOpen={setOpenEvaluationDialog}
				evaluationDialogState={evaluationDialogState}
				classForEvaluatedDialog={classForEvaluatedDialog}
				handleEvaluatedDialogConfirmation={handleEvaluatedDialogConfirmation}
			/>

			<ClassAdjustCombineDialog
				open={openClassAdjustCombineDialog}
				setOpen={setOpenClassAdjustCombineDialog}
				list={classesForCombining}
				setList={setClassesForCombining}
				rows={props.rows}
				setRows={props.setRows}
				setCombiningMode={props.setCombiningMode}
				teamTeachingAgree={teamTeachingAgree}
				setUnsavedChanges={props.setUnsavedChanges}
				unwantedCombination={unwantedCombination}
				setUnwantedCombination={setUnwantedCombination}
				selectedClass={selectedClass}
			/>

			<UndoCombineDialog
				open={undoDialogOpen}
				handleUndo={handleUndo}
				setOpen={setOpenUndoDialog}
			/>

			<Snackbar
				open={snackbarOpen}
				autoHideDuration={5000}
				onClose={handleCloseSnackbar}
				message={snackbarMessage}
			/>
			<div className="flex  h-full w-full items-center justify-center px-4">
				{/* <ClassPanel
					selectedClass={selectedClass}
					handleSaveChanges={handleSaveChanges}
					handleDiscardChanges={handleDiscardChanges}
					handleDateChangeEndDate={handleDateChangeEndDate}
					handleDateChangeExamDate={handleDateChangeExamDate}
					handleDateChangeSurveyStart={handleDateChangeSurveyStart}
					handleDateChangeSurveyEnd={handleDateChangeSurveyEnd}
					handleToggleExclude={handleToggleExclude}
					handleCombineClass={handleCombineClass}
					handleCancelCombine={handleCancelCombine}
					handleChangeRole={handleChangeRole}
					deleteInstructor={deleteInstructor}
					roles={roles}
					combiningMode={combiningMode}
					setCombiningMode={setCombiningMode}
					classesForCombining={classesForCombining}
					setClassesForCombining={setClassesForCombining}
					setOpenClassAdjustCombineDialog={setOpenClassAdjustCombineDialog}
					teamTeachingAgree={teamTeachingAgree}
					setTeamTeachingAgree={setTeamTeachingAgree}
					checkboxSelectionState={checkboxSelectionState}
					undoCombine={undoCombine}
					handleSaveAttempt={handleSaveAttempt}
					hasNoRoles={hasNoRoles}
					hasNoBlockId={hasNoBlockId}
					isClassSizeTooSmall={isClassSizeTooSmall}
					isClassSizeZero={isClassSizeZero}
					validateClass={validateClass}
					unsavedChanges={props.unsavedChanges}
					setUnsavedChanges={props.setUnsavedChanges}
					blockIdsByDept={props.blockIdsByDept}
					allBlockItems={props.allBlockItems}
					handleChangeBlkId={props.handleChangeBlkId}
					rows={props.rows}
					setRows={props.setRows}
					setAddInstructor={setAddInstructor}
					addInstructor={addInstructor}
					handleOpenAddInstancedClass={handleOpenAddInstancedClass}
				/> */}
				<div
					className={`flex flex-col h-full ${
						selectedClass ? "w-[900px]" : "w-[900px]"
					}`}
				>
					<div className="flex flex-col items-center justify-between w-full border-2 px-2 rounded-l-md rounded-b-none">
						<HeaderSection
							terms={props.terms}
							termValue={props.termValue}
							setTermValue={props.setTermValue}
							departmentsList={props.departmentsList}
							selectedDept={props.selectedDept}
							handleDepartmentChange={props.handleDepartmentChange}
							handleRefresh={props.handleRefresh}
							loading={props.loading}
							unsavedChanges={props.unsavedChanges}
							dateFormat={dateFormat}
							setDateFormat={setDateFormat}
						/>
						<div className="h-10 w-full mb-2">
							<label
								htmlFor="search"
								className="sr-only"
							>
								Search
							</label>
							<input
								id="search"
								type="text"
								placeholder="Search for a Class ID, Class Title, or Instructor Name"
								className="h-10 w-full border-[1px] border-gray-300 focus:outline-green-800 py-1 px-3 rounded-md text-black placeholder-gray-600"
								value={searchValue}
								onChange={handleSearch}
							/>
						</div>
					</div>
					<DataGridTable
						rows={props.rows}
						setRows={props.setRows}
						loading={props.loading}
						handleSelectionModelChange={handleSelectionModelChange}
						getRowClassName={getRowClassName}
						filterKey={filterKey}
						unsavedChanges={props.unsavedChanges}
						reviewingClasses={reviewingClasses}
						combiningMode={props.combiningMode}
						customCheckboxSelection={customCheckboxSelection}
						isDragging={isDragging}
						// handleMouseDown={handleMouseDown}
						// handleMouseEnter={handleMouseEnter}
						handleClickAddClassCheckbox={handleClickAddClassCheckbox}
						handleSubmitAllSuitable={handleSubmitAllSuitable}
						confirmSubmitAllSelected={confirmSubmitAllSelected}
						handleReviewAllSelected={handleReviewAllSelected}
						submitAllLoading={submitAllLoading}
						handleSubmitAllSelected={handleSubmitAllSelected}
						handleCancelSubmitAllSelected={handleCancelSubmitAllSelected}
						batchUpdated={batchUpdated}
						openBatchUpdateDialog={openBatchUpdateDialog}
						setOpenBatchUpdateDialog={setOpenBatchUpdateDialog}
						setConfirmSubmitAllSelected={setConfirmSubmitAllSelected}
						handleCancelSubmitAllSuitable={handleCancelSubmitAllSuitable}
						handleConfirmSubmitAllSuitable={handleConfirmSubmitAllSuitable}
						renderInstructorNameTable={renderInstructorNameTable}
						hasNoBlockIdCheck={hasNoBlockIdCheck}
						hasNoRolesCheck={hasNoRolesCheck}
						isClassSizeTooSmallCheck={isClassSizeTooSmallCheck}
						isClassSizeZeroCheck={isClassSizeZeroCheck}
						handleClick={handleClick}
						validateClass={validateClass}
						dateFormat={dateFormat}
						allBlockItems={props.allBlockItems}
						handleChangeBlkId={props.handleChangeBlkId}
						setRowsForTableOriginal={props.setRowsForTableOriginal}
						setUnsavedChanges={props.setUnsavedChanges}
						setSelectedClass={setSelectedClass}
						selectedClass={selectedClass}
						setCheckboxSelectionState={setCheckboxSelectionState}
						checkboxSelectionState={checkboxSelectionState}
					/>
				</div>
				{selectedClass && (
					<ClassPanel
						selectedClass={selectedClass}
						handleSaveChanges={handleSaveChanges}
						handleDiscardChanges={handleDiscardChanges}
						handleDateChangeEndDate={handleDateChangeEndDate}
						handleDateChangeExamDate={handleDateChangeExamDate}
						handleDateChangeSurveyStart={handleDateChangeSurveyStart}
						handleDateChangeSurveyEnd={handleDateChangeSurveyEnd}
						handleToggleExclude={handleToggleExclude}
						handleCombineClass={handleCombineClass}
						handleCancelCombine={handleCancelCombine}
						handleChangeRole={handleChangeRole}
						deleteInstructor={deleteInstructor}
						roles={roles}
						combiningMode={props.combiningMode}
						setCombiningMode={props.setCombiningMode}
						classesForCombining={classesForCombining}
						setClassesForCombining={setClassesForCombining}
						setOpenClassAdjustCombineDialog={setOpenClassAdjustCombineDialog}
						teamTeachingAgree={teamTeachingAgree}
						setTeamTeachingAgree={setTeamTeachingAgree}
						checkboxSelectionState={checkboxSelectionState}
						undoCombine={undoCombine}
						handleSaveAttempt={handleSaveAttempt}
						hasNoRoles={hasNoRoles}
						hasNoBlockId={hasNoBlockId}
						isClassSizeTooSmall={isClassSizeTooSmall}
						isClassSizeZero={isClassSizeZero}
						validateClass={validateClass}
						unsavedChanges={props.unsavedChanges}
						setUnsavedChanges={props.setUnsavedChanges}
						blockIdsByDept={props.blockIdsByDept}
						allBlockItems={props.allBlockItems}
						handleChangeBlkId={props.handleChangeBlkId}
						rows={props.rows}
						setRows={props.setRows}
						setAddInstructor={setAddInstructor}
						addInstructor={addInstructor}
						handleOpenAddInstancedClass={handleOpenAddInstancedClass}
					/>
				)}
			</div>
		</div>
	);
}
