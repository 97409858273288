import React from "react";
import { Button, Tooltip } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import DeleteForever from "@mui/icons-material/DeleteForever";
import WarningIcon from "@mui/icons-material/Warning";
import dayjs from "dayjs";
import { DatePickerComponent } from "./DatePickerComponent";
import ToggleExclude from "./ToggleExclude";
import TabsClassAdjustment from "./TabsClassAdjustment";
import BlockIdSelect from "./BlockIdSelect";

export default function ClassPanel({
	selectedClass,
	handleSaveChanges,
	handleDiscardChanges,
	handleDateChangeEndDate,
	handleDateChangeExamDate,
	handleDateChangeSurveyStart,
	handleDateChangeSurveyEnd,
	handleToggleExclude,
	handleCombineClass,
	handleCancelCombine,
	handleChangeRole,
	deleteInstructor,
	roles,
	combiningMode,
	setCombiningMode,
	classesForCombining,
	setClassesForCombining,
	setOpenClassAdjustCombineDialog,
	teamTeachingAgree,
	setTeamTeachingAgree,
	checkboxSelectionState,
	undoCombine,
	handleSaveAttempt,
	hasNoRoles,
	hasNoBlockId,
	isClassSizeTooSmall,
	isClassSizeZero,
	validateClass,
	unsavedChanges,
	setUnsavedChanges,
	blockIdsByDept,
	allBlockItems,
	handleChangeBlkId,
	rows,
	setRows,
	setAddInstructor,
	addInstructor,
	allBlockItemsLoading,
	blockItemsStatus,
	handleOpenAddInstancedClass,
}) {
	return (
		<div
			className={`flex flex-col  items-center w-[350px] h-full border-2 ${
				selectedClass
					? unsavedChanges
						? "border-red-800 "
						: "border-green-800"
					: ""
			} rounded-e-md overflow-x-hidden`}
		>
			<div className="w-full bg-white flex flex-col h-full justify-between relative">
				{selectedClass ? (
					<>
						{selectedClass.isCombined && (
							<div>
								<div className="border-red-600 border-2 rounded-md bg-red-100 text-red-900 text-sm max-w-[90%] max-h-[70%] p-4 absolute m-auto left-0 right-0 top-[25%] z-20 flex flex-col items-center justify-center gap-4 ">
									<div className="max-w-[90%] flex flex-col gap-4">
										<p className="">
											This class is currently combined with{" "}
											<span className="font-bold">
												{selectedClass.combinedWith}
											</span>
										</p>
										<p className="">
											Classes that have been combined with another cannot be
											modified as they will not be surveyed.
										</p>
										<p className="">
											If you would like to modify this class and evaluate it
											separately, please undo the combine operation on the
											parent class{" "}
											<span className="font-bold">
												{selectedClass.combinedWith}
											</span>
										</p>
										<p className="">
											This can be done by selecting the parent class and
											clicking the "Undo Combine" button under the "Combining"
											tab.
										</p>
									</div>
								</div>
							</div>
						)}
						<div className="flex flex-col justify-between h-full border-b-2 px-2 py-1">
							<div className="flex flex-col justify-between    ">
								<div
									className="w-full mx-auto  flex gap-2"
									onClick={handleSaveAttempt}
								>
									{hasNoRoles ||
									hasNoBlockId ||
									isClassSizeTooSmall ||
									isClassSizeZero ? (
										<Tooltip
											title={validateClass(selectedClass).message}
											placement="bottom"
										>
											<Button
												variant="contained"
												/* onClick={handleSaveChanges} */
												color="warning"
												className="flex items-center gap-1 w-full"
											>
												SAVE <WarningIcon />
											</Button>
										</Tooltip>
									) : (
										<Button
											variant="contained"
											disabled={!unsavedChanges}
											onClick={handleSaveChanges}
											className="flex items-center gap-1 w-full"
										>
											SAVE/SUBMIT
											<SaveIcon />
										</Button>
									)}

									<Button
										disabled={!unsavedChanges}
										variant="contained"
										color="error"
										onClick={handleDiscardChanges}
										className="flex items-center gap-1 w-full"
									>
										RESET
										<DeleteForever />
									</Button>
								</div>
								<h1 className=" grid grid-cols-6 p-2">
									<span
										className={`font-bold col-span-4 antialiased ${
											selectedClass.ctitle.length > 20 ? "text-md " : "text-lg "
										}`}
									>
										{selectedClass.ctitle}{" "}
									</span>
									<span className="font-bold text-lg col-span-2">
										ID: {selectedClass.classid}
									</span>
								</h1>

								<div className="w-full border-b-2"></div>
								<div className="grid grid-cols-9 p-2">
									<p className="text-sm flex justify-between col-span-4">
										# of students:{" "}
										<span className="font-bold">{selectedClass.csize}</span>
									</p>
									<div className="h-full w-full col-span-1 grid grid-cols-2">
										<div className="h-full w-full border-r-2 col-span-1"></div>
										<div className="h-full w-full col-span-1"></div>
									</div>
									<p className=" text-sm flex justify-between col-span-4">
										# of instructors:{" "}
										<span className="font-bold">{selectedClass.teamsize}</span>
									</p>
								</div>

								<div className="grid grid-cols-2 gap-2">
									<DatePickerComponent
										disabled={selectedClass.isCombined}
										width="100%"
										label="Class End Date"
										value={dayjs(selectedClass.c_edate)}
										onChange={handleDateChangeEndDate}
									/>
									<DatePickerComponent
										disabled={selectedClass.isCombined}
										width="100%"
										label="Exam Date"
										value={dayjs(selectedClass.examdate)}
										onChange={handleDateChangeExamDate}
										confirm
									/>

									<DatePickerComponent
										disabled={selectedClass.isCombined}
										width="100%"
										label="Survey Start Date"
										value={dayjs(selectedClass.survey_start)}
										maxDate={dayjs(selectedClass.survey_end)}
										onChange={handleDateChangeSurveyStart}
									/>
									<DatePickerComponent
										disabled={selectedClass.isCombined}
										width="100%"
										label="Survey End Date"
										value={dayjs(selectedClass.survey_end)}
										maxDate={
											dayjs(selectedClass.examdate)
												? dayjs(selectedClass.examdate).subtract(1, "day")
												: null
										}
										onChange={handleDateChangeSurveyEnd}
									/>
								</div>
							</div>
							{/* <div className="w-full border-b-2"></div> */}
							<div className=" flex gap-2 w-full ">
								<div className="flex p-2 bg-gray-100 rounded-md flex-col w-1/2 items-start justify-between border-2 px-2 py-1 rounded-md">
									<label className="text-zinc-900 text-sm font-semibold">
										Block ID:
									</label>
									<BlockIdSelect
										disabled={allBlockItemsLoading || combiningMode}
										blockIds={allBlockItems}
										value={selectedClass.blkid ? selectedClass.blkid : ""}
										onChange={(event) => handleChangeBlkId(event)}
										width="100%"
										marginTop="0px"
										marginBottom="0px"
										blockItemsStatus={blockItemsStatus}
									/>
								</div>
								<ToggleExclude
									row={selectedClass}
									handleToggleExclude={handleToggleExclude}
									disabled={selectedClass.isCombined}
								/>
							</div>

							<TabsClassAdjustment
								handleCancelCombine={handleCancelCombine}
								handleCombineClass={handleCombineClass}
								handleChangeBlkId={handleChangeBlkId}
								disabled={selectedClass.isCombined}
								blockIdsByDept={blockIdsByDept}
								blockIds={allBlockItems}
								selectedClass={selectedClass}
								row={selectedClass}
								rows={rows}
								setRows={setRows}
								setAddInstructor={setAddInstructor}
								addInstructor={addInstructor}
								handleChangeRole={handleChangeRole}
								deleteInstructor={deleteInstructor}
								roles={roles}
								setUnsavedChanges={setUnsavedChanges}
								combiningMode={combiningMode}
								setCombiningMode={setCombiningMode}
								classesForCombining={classesForCombining}
								setClassesForCombining={setClassesForCombining}
								setOpenClassAdjustCombineDialog={
									setOpenClassAdjustCombineDialog
								}
								teamTeachingAgree={teamTeachingAgree}
								setTeamTeachingAgree={setTeamTeachingAgree}
								checkboxSelectionState={checkboxSelectionState}
								undoCombine={undoCombine}
								handleOpenAddInstancedClass={handleOpenAddInstancedClass}
							/>
						</div>
					</>
				) : (
					<div className="w-full h-full bg-white flex flex-col justify-center items-center border-b-2 py-2">
						<div className="border-2 rounded-md p-2 text-center border-green-800">
							<h1 className="text-lg">Edit a Class</h1>
							<p className="text-sm px-2">
								Select a class from the table to make edits.
							</p>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
